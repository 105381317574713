body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  width: 100%;
  height: 100%;
}
html, body, div, ul, li, p {
  display: block;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
}
button {
  border: none;
}
html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {-webkit-text-size-adjust:none;}

* {
  -webkit-touch-callout:none;/*系统默认菜单被禁用*/
  -webkit-user-select:none;/*webkit浏览器*/
  -khtml-user-select:none;/*早起浏览器*/
  -moz-user-select:none;/*火狐浏览器*/
  -ms-user-select:none;/*IE浏览器*/
  user-select:none;/*用户是否能够选中文本*/
}

#root {
  width: 100%;
  height: 100%;
  font-size: 0.14rem;
  overflow: hidden;
}