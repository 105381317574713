.detail-box {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(38, 39, 40, 0.95);
    display: none;
    z-index: 100;
}
.detail-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.37rem 0 0.37rem 0.32rem;
    overflow-y: auto;
}
.detail-modal-title {
    font-size: 0.32rem;
    color: #FFFFFF;
    padding-bottom: 0.12rem;
    border-bottom: 0.02rem solid #7F7F7F;
    font-weight: bold;
    margin-bottom: 0.47rem;
}

.detail-sec-title {
    padding-bottom: 0.3rem;
    color: #FFFFFF;
    font-size: 0.28rem;
    font-weight: bold;
}
.detail-baseinfo-list {
    padding-left: 0.3rem;
    color: #C2C2C2;
    font-size: 0.28rem;
    padding-bottom: 0.4rem;
}
.detail-baseinfo-list > div {
    padding-bottom: 0.12rem;
    display: flex;
    width: calc(100% - 0.5rem);
}
.detail-baseinfo-text {
    flex: 1;
    word-break: break-all;
}
.detail-capacity-list {
    display: flex;
    font-size: 0.28rem;
    color: #C2C2C2;
    padding-left: 0.3rem;
}
.detail-capacity-item {
    margin-right: 1.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.detail-capacity-item:nth-last-child(1) {
    margin-right: 0;
}
.detail-capacity-item-text {
    padding: 0.1rem 0 0.06rem;
}
.detail-capacity-icon-box {
    width: 0.92rem;
    height: 0.92rem;
    border-radius: 0.02rem;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #262728;
}
.detail-capacity-icon-box span {
    font-size: 0.8rem;
}
.close-btn {
    position: fixed;
    right: 0.3rem;
    bottom: 0.3rem;
    width: 0.72rem;
    height: 0.72rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}