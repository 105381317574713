.info-page {
    width: 100%;
    height: 100%;
    background: #F4F8FB;
    overflow-y: auto;
    font-size: 0.28rem;
}
.info-title {
    font-size: 0.32rem;
    color: #262626;
    padding: 0.35rem 0 0.1rem 0;
    font-weight: bold;
    border-bottom: 0.02rem solid #7F7F7F;
    margin-left: 0.3rem;
}
.info-content {
    padding: 0.3rem 0.3rem 0.8rem;
}

.block-title {
    color: #262626;
    font-weight: bold;
    padding-bottom: 0.2rem;
}
.block-detail {
    padding: 0 0.3rem;
}
.block-detail > div {
    padding-bottom: 0.12rem;
    color:#4D4D4D;
    display: flex;
    align-items: center;
    line-height: 0.38rem;
}
.block-detail-text {
    flex: 1;
    word-break: break-all;
}
.detial-img {
    margin-top: 0.1rem;
}
.detial-img img{
    width: 6.86rem;
    height: 3.8rem;
    display: block;
    margin: 0 auto;
}
.detial-img video {
    width: 100%;
}
.space-info-title {
    color: #262626;
    font-weight: bold;
}
.back-btn {
    width: 0.73rem;
    height: 0.73rem;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0.3rem;
    bottom: 0.3rem;
}
.back-btn span{
    font-size: 0.26rem;
}

.space-capacity-list {
    padding-left: 0.3rem;
    padding-top: 0.2rem;
}
.capacity-item {
    color: rgba(77, 77, 77, 0.8);
    margin-right: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.capacity-item:nth-last-child(1) {
    margin-right: 0;
}
.capacity-icon-box {
    width: 0.92rem;
    height: 0.92rem;
    box-shadow: 0 0.02rem 0.07rem 0 rgba(207, 210, 223, 0.54);
    background: #FFFFFF;
    border-radius: 0.02rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.capacity-icon-box span{
    font-size: 0.66rem;
    color: #262728;
}
.capacity-item-text {
    padding-top: 0.2rem;
}