.panorama-page {
    width: 100%;
    height: 100%;
}
.panorama-box {
    width: 100%;
    height: 100%;
}
.img-list-box {
    width: 1.95rem;
    height: 100%;
    background: rgba(0,0,0,0.8);
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s;
    z-index: 10;
    padding-top: 0.28rem;
}
.img-list-box .img {
    width: 1.36rem;
    height: 1.36rem;
    border: 0.02rem solid transparent;
    border-radius: 0.04rem;
    cursor: pointer;
    margin-bottom: 0.28rem;
    color: #fff
}
.btn-list {
    position: fixed;
    right: 0.3rem;
    bottom: 0;
    z-index: 1;
}
.btn {
    width: 0.72rem;
    height: 0.72rem;
    background: rgba(255,255,255,0.9);
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3rem;
}

/* 全景图相关 */
.custom-hotspot {
    width: 0.4rem;
    height: 0.4rem;
    background: url("src/assets/img/arrow.png") no-repeat center;
    background-size: 100%;
}
div.custom-tooltip span {
    position: absolute;
    border-radius: 0.04rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFFFFF;
    /* max-width: 2rem; */
    padding: 0.07rem 0.09rem;
    /* margin-left: -2.2rem; */
    cursor: default;
    font-size: 0.12rem;
    word-break: break-all;
    white-space: nowrap;
    transform: translateX(0.4rem);
    text-align: center;
}